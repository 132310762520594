<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import Disclaimer from '@/components/widgets/disclaimer'
import { authComputed } from "@/state/helpers";
import Listings from '@/components/tables/listings'

export default {
  components: {
    Layout,
    PageHeader,
    Disclaimer,
    Listings,
  },
  data() {
    return {
      assetType: 'etfs',
      title: "Top ETFs to Buy",
      breadcrumbs: [
        {
          text: "ETFs",
          // href: "/",
          active: true, // remove to activate href
        },
      ],
      currentPage: parseInt(this.pagenum),
      pageSize: 10,
      apiDataLoaded: false,
      metaDataLoaded: false,
      apiData: {
        symbol: '',
        name: ''
      },
      metaData: {
        total_count: 10,
      },
      scopes: {
        intraday: {
          value: "intraday",
          snake: "intraday",
          train: "Intraday",
          kebab: "intraday"
        },
        shortterm: {
          value: "shortterm",
          snake: "short_term",
          train: "Short-Term",
          kebab: "short-term"
        },
        mediumterm: {
          value: "mediumterm",
          snake: "medium_term",
          train: "Medium-Term",
          kebab: "medium-term"
        },
        longterm: {
          value: "longterm",
          snake: "long_term",
          train: "Long-Term",
          kebab: "long-term",
        }
      },
    };
  },
  methods: {
    changePage(pageNumber) {
      this.$router.push(this.linkGen(pageNumber))
      this.getApiData()
    },
    async getApiData() {
      this.apiDataLoaded = false
      let limit = this.pageSize || 10
      let skip = parseInt(this.currentPage) * limit - limit || 0
      if (this.selectedScope) {
        try {
          const result = await axios.get(
            `https://api.bulldata.ai/${this.assetType}/buy/?skip=${skip}&limit=${limit}&scope=${this.scopes[this.selectedScope]['snake']}`,
            { headers: { token: process.env.VUE_APP_BULLDATA_API_TOKEN } }
          )
          this.apiData = await result.data.data
          this.apiDataLoaded = true
          console.log("Data loaded.")
        } catch (error) {
          console.error(error)
        }
      }
    },
    getMetaData() {
      axios.get(
        `https://api.bulldata.ai/meta/${this.assetType}`,
        { headers: { token: process.env.VUE_APP_BULLDATA_API_TOKEN } }
      )
      .then(
        result => {
          this.metaData = result.data.data
          this.metaDataLoaded = true
          console.log("Metadata loaded.")
        },
        error => {
          console.error(error)
        }
      )
    },
    truncate(value, length) {
      if (window.innerWidth < 768) {
        if (value.length > length) {
          return value.substring(0, length) + "…";
        } else {
          return value;
        }
      } else {
        return value;
      }
    },
    formatPrice(price) {
      if (price >= 1) {
        return new Intl.NumberFormat(
          'en-US', 
          { 
            style: 'currency', 
            currency: 'USD', 
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }
        ).format(price)
      }
      else {
        return new Intl.NumberFormat(
          'en-US', 
          { 
            style: 'currency', 
            currency: 'USD', 
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
          }
        ).format(price)
      }
    },
    formatPercent (percent) {
      return new Intl.NumberFormat(
        'en-US', 
        { 
          style: 'percent', 
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          signDisplay: 'always'
        }
      ).format(percent)
    },
    getScoreColor(score) {
      if(score < 0) {
        return '#DD3444'
      } else {
        return '#28A745'
      }
    },
    getScoreEmoji(score) {
      if (score >= .4) return '🚀'
      else if (score <= -.4) return '☢️'
      else if (score >= .2 && score < .4) return '🔥'
      else if (score <= -.2 &&	score > -.4) return '⚡'
      else if (score > -.2 && score < .2) return '🌱'
      else return '🌱'
    },
    getBadgeClass(score) {
      return {
        'badge badge-score-label badge-strong-buy': score >= 40,
        'badge badge-score-label badge-strong-sell': score <= -40,
        'badge badge-score-label badge-buy': (score >= 20) && (score < 40),
        'badge badge-score-label badge-sell': (score <= -20) && (score > -40),
        'badge badge-score-label badge-neutral': (score > -20) &&	(score < 20)
      }
    },
    getBadgeClassStrong(score) {
      return {
        'badge badge-score-strong badge-strong-buy': score >= 40,
        'badge badge-score-strong badge-strong-sell': score <= -40,
        'badge badge-score-strong badge-buy': (score >= 20) && (score < 40),
        'badge badge-score-strong badge-sell': (score <= -20) && (score > -40),
        'badge badge-score-strong badge-neutral': (score > -20) &&	(score < 20)
      }
    },
    getBadgeLabel(score) {
      if (score >= 40) return 'strong buy'
      else if (score <= -40) return 'strong sell'
      else if (score >= 20 && score < 40) return 'buy'
      else if (score <= -20 &&	score > -40) return 'sell'
      else if (score > -20 && score < 20) return 'neutral'
      else return ''
    },
    getColorClass(number) {
      if (number < 0) return 'color-down'
      else if (number >= 0) return 'color-up'
      else return ''
    },
    toSparklineSeries(array, symbol) {
      return [{
        name: symbol,
        data: array
      }]
    },
    getSparklineColor(item, days) {
      if (days == 7) {
        return (item.rel_deltas.rel_delta_7_days < 0) ? ['#ff0000'] : ['#008000']
      }
      else if (days == 30) {
        return (item.rel_deltas.rel_delta_30_days < 0) ? ['#ff0000'] : ['#008000']
      }
      else if (days == 360) {
        return (item.rel_deltas.rel_delta_200_days < 0) ? ['#ff0000'] : ['#008000']
      }
    },
    getSparklineChartOptions(item, days) {
      return {
        chart: {
          type: 'area',
          sparkline: {
            enabled: true
          }
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        colors: this.getSparklineColor(item, days),
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [25, 100, 100, 100]
          },
        },
        tooltip: {
          enabled: false,
        }
      }
    },
    scoreTransformed(score) {
      return Math.round(score * 100) || 0
    },
    linkGen(pageNum) {
      return {
        name: `${this.assetType}-buy`,
        params: { pagenum: pageNum }
      }
    },
  },
  mounted() {
    this.getApiData()
    this.getMetaData()
  },
  props: {
    pagenum: {type: String, required: true},
  },
  watch: {
    selectedScope() {
      this.getApiData()
    }
  },
  computed: {
    ...authComputed,
    selectedScope() {
      return this.userData.preferredTerm
    },
    page() {
      return {
        title: `Top ${this.capitalize(this.assetType)} to Buy`,
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      }
    },
  }
}
</script>

<style scoped>

.dash-card {
  background-color: #fff;
  box-shadow: none;
}

.asset-price {
  font-size: 27px;
  font-weight: 300;
}
.bulldata-score {
  font-size: 24px;
  font-weight: 400;
}
.fira {
  font-family: "Fira Code", "Lucida Console", monospace;
}
.card-body-listings {
  padding: 12px;
}
.color-down {
  color: #ff0000;
}
.color-up {
  color: #008000;
}
.rel-delta {
  font-size: 18px;
  font-weight: 300;
}
.badge-score-label {
  font-family: "Fira Code", "Lucida Console", monospace;
  font-size: 13px;
  font-weight: 300;
  padding: 0.5em 0.5em;
}
.badge-score-strong {
  font-family: "Fira Code", "Lucida Console", monospace;
  font-size: 13px;
  font-weight: 400;
  padding: 0.5em 0.5em;
}
.badge-buy {
  background-color: #008000;
}
.badge-sell {
  background-color: #ff0000;
}
.badge-strong-buy {
  background-color: #008000;
}
.badge-strong-sell {
  background-color: #ff0000;
}
.badge-neutral {
  background-color: #5f6875;
}

.table-first-row {
  border-top: 1px solid;
}

.score-header {
  font-weight: 600;
  text-transform: uppercase;
}

.score-row {
  font-family: "Lucida Console", "Courier New", monospace;
}

.sticky-col {
  position: sticky;
  left: 0;
  background-color: #ffffff;
}

.overapex {
  z-index: 12;
}

</style>

<template>
  <Layout>
    <PageHeader 
      :title="title" 
      :items="breadcrumbs"
    />
    
    <div class="row" v-if="!apiDataLoaded">
      <div class="col-xl-4 col-md-6" v-for="item in 3" :key="item">
        <div class="card">
          <div class="card-body">
            <div>
              <h4 class="card-title mb-4 placeholder-glow">
                <span class="placeholder col-6"></span>
              </h4>
            </div>
            <b-card-text>
              <div class="font-size-13 score-header placeholder-glow">
                <span class="placeholder col-6"></span>
              </div>
              <div>
                <p class="placeholder-glow">
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                  <span class="placeholder col-8"></span>
                </p>
              </div>
              <div>
                <p class="placeholder-glow">
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                </p>
              </div>
              <div class="font-size-13 score-header placeholder-glow">
                <span class="placeholder col-6"></span>
              </div>
              <div>
                <p class="placeholder-glow">
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-7"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-6"></span>
                  <span class="placeholder col-8"></span>
                  <span class="placeholder col-4"></span>
                  <span class="placeholder col-4"></span>
                </p>
              </div>
            </b-card-text>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="apiDataLoaded">
      <div class="col-lg-12">
        <div class="card dash-card">
          <div class="card-body card-body-listings px-0">
            <b-card-text>
              <Listings
                :asset-type="assetType"
                :api-data="apiData"
                :meta-data="metaData"
                :selected-scope="selectedScope"
              />
            </b-card-text>
          </div>
          <div class="card-footer bg-transparent">
            <div class="text-center">
              <div class="overflow-auto">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.metaData.total_count"
                  :per-page="this.pageSize"
                  align="center"
                  first-number
                  last-number
                  @update:modelValue="changePage"
                  size="sm"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Disclaimer/>

  </Layout>
</template>
